import React from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'


const platformName = process.env.NEXT_PUBLIC_APP_NAME

interface Props {
  children: React.ReactNode
  title: string
  metaTitle?: string | undefined
  description?: string | undefined | null
  image?: string | undefined | null
}

export const PageWrapper: React.FC<Props> = (props) => {
  const { children, title, metaTitle, description: descriptionRaw, image } = props
  const { t}  = useTranslation('homePage')
  const router = useRouter()
  const description = null // useTipTapJsonToPlainText({ json: descriptionRaw }) @todo change when editor fixed
  const defaultDescription = t('metaDescription', { platformName })
  const defaultImage = `${process.env.NEXT_PUBLIC_APP_URL}/images/plantico-logo.png`
  return (
    <>
      <Head>
        <title>{`${title} | ${platformName}`}</title>
        <meta name="Description" content={description || defaultDescription} />
        <meta itemProp="description" content={description || defaultDescription} />
        <meta name="twitter:description" content={description || defaultDescription} />
        <meta property="og:description" content={description || defaultDescription} />
        <meta itemProp="name" content={metaTitle || title} />
        <meta name="twitter:title" content={metaTitle || title} />
        <meta property="og:title" content={metaTitle || title} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta itemProp="image" content={image || defaultImage} />
        <meta name="twitter:image:src" content={image || defaultImage} />
        <meta property="og:image" content={image || defaultImage} />
        <meta property="og:url" content={`${process.env.NEXT_PUBLIC_APP_URL}${router.asPath}`} />
      </Head>
      {children}
    </>
  )
}
