import React from 'react'
import { chakra, HTMLChakraProps } from '@chakra-ui/react'

export function HeadingPill({
  bgColor,
  translateY = '-50%',
  rotate = '-3deg',
  children,
  ...rest
}: HTMLChakraProps<'span'>) {
  return (
    <chakra.span
      {...rest}
      position="relative"
      _after={{
        position: 'absolute',
        top: '50%',
        left: '-6px',
        right: '-6px',
        height: { base: '8', md: '10' },
        translateY,
        rotate,
        transform: 'translateY(var(--chakra-translate-y)) rotateZ(var(--chakra-rotate))',
        padding: '8px',
        content: '""',
        display: 'block',
        borderRadius: 'md',
        bgColor,
        zIndex: -1,
      }}
    >
      {children}
    </chakra.span>
  )
}
