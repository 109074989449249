import React from 'react'
import Image from 'next/legacy/image'
import {
  chakra,
  Text,
  VStack,
  Collapse,
  Icon,
  HTMLChakraProps,
  useColorModeValue,
} from '@chakra-ui/react'
import { FaChevronDown } from 'react-icons/fa'
import useTranslation from 'next-translate/useTranslation'
import { usePlatformConfig } from 'utils/hooks'

const platformName = process.env.NEXT_PUBLIC_APP_NAME
const transactionFee = process.env.NEXT_PUBLIC_TRANSACTION_FEE

export function FAQ(props: HTMLChakraProps<'div'>) {
  const textColor = useColorModeValue(undefined, 'white')
  const cardBorder = useColorModeValue('transparent', 'yellow.300')
  const cardBg = useColorModeValue('white', 'rgba(0, 0, 0, 0.8)')
  const { t } = useTranslation('homePage')
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null)

  const FAQ_ITEMS = [
    {
      title: t('faqSection.question1', { platformName }),
      description: t('faqSection.answer1', { platformName }),
    },
    {
      title: t('faqSection.question2', { platformName }),
      description: t('faqSection.answer2', { platformName }),
    },
    {
      title: t('faqSection.question3', { platformName }),
      description: t('faqSection.answer3', { platformName }),
    },
    {
      title: t('faqSection.question4', { platformName }),
      description: t('faqSection.answer4', { platformName, transactionFee }),
    },
    {
      title: t('faqSection.question5'),
      description: t('faqSection.answer5', { platformName }),
    },
  ]

  return (
    <VStack alignItems="stretch" spacing="4" {...props}>
      {FAQ_ITEMS.map(({ title, description }, index) => {
        const isActive = activeIndex === index
        return (
          <chakra.div
            key={title}
            width="100%"
            boxShadow="0px 14px 40px rgba(132, 132, 132, 0.14)"
            borderColor={cardBorder}
            borderWidth="1px"
            borderStyle="solid"
            bg={cardBg}
            borderRadius={{ base: 'xl', md: '3xl' }}
          >
            <chakra.button
              width="100%"
              color={textColor}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              textAlign="left"
              p={{ base: '5', md: '7' }}
              cursor="pointer"
              _focus={{
                boxShadow: 'focus',
              }}
              onClick={() =>
                setActiveIndex((activeIndex) => (activeIndex === index ? null : index))
              }
            >
              <Text fontSize={{ base: 'md', md: 'lg' }} fontWeight="medium" pr="2">
                {title}
              </Text>
              <Icon
                as={FaChevronDown}
                width={{ base: '4', md: '6' }}
                height={{ base: '4', md: '6' }}
                transition="transform 0.125s ease-out"
                transform={`rotateX(${isActive ? 180 : 0}deg)`}
              />
            </chakra.button>
            <Collapse in={isActive} animateOpacity>
              <Text
                px={{ base: '5', md: '10' }}
                pt={{ base: '0', md: '3' }}
                pb="6"
                fontSize={{ base: 'sm', md: 'md' }}
                lineHeight="tall"
                color={textColor}
              >
                {description}
              </Text>
            </Collapse>
          </chakra.div>
        )
      })}
    </VStack>
  )
}

export function Hand(props: HTMLChakraProps<'div'>) {
  const config = usePlatformConfig()

  return (
    <>
      <chakra.div
        position="absolute"
        right={0}
        justifyContent="flex-end"
        maxH="80vw"
        maxW="60vw"
        {...props}
        display={{ base: 'none', md: 'flex' }}
        __css={{
          '> div': {
            height: '100%',
            width: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
          },
        }}
      >
        <Image src={config.images.faq.url} alt="" objectFit="fill" width="494" height="900" />
      </chakra.div>
      <chakra.div
        position="absolute"
        right="-4vw"
        justifyContent="flex-end"
        maxH="60vw"
        maxW="40vw"
        {...props}
        display={{ base: 'flex', md: 'none' }}
        __css={{
          '> div': {
            height: '100%',
            width: 'auto',
            justifyContent: 'flex-end',
          },
        }}
      >
        <Image src={config.images.faq.url} alt="" objectFit="fill" width="494" height="900" />
      </chakra.div>
    </>
  )
}
