import React from 'react'
import Image from 'next/legacy/image'
import { chakra, HTMLChakraProps, useColorModeValue } from '@chakra-ui/react'

export const useDarkSuffix = () => useColorModeValue('', '-dark')

export function Spill1(props: HTMLChakraProps<'div'>) {
  return (
    <chakra.div
      position="absolute"
      right={0}
      top={0}
      width={{ base: '100%', md: '60vw' }}
      maxH={{ base: '108vw', md: '70vw' }}
      maxW={{ base: '98vw', sm: '93vw', md: '840px' }}
      pointerEvents="none"
      {...props}
    >
      <chakra.div
        pt="108%"
        display="flex"
        justifyContent="flex-end"
        __css={{
          '> div': {
            height: '100%',
            width: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
          },
        }}
      >
        <Image src={`/images/homepage/splash-section-1${useDarkSuffix()}.svg`} alt="" objectFit="cover" layout="fill" />
      </chakra.div>
    </chakra.div>
  )
}

export function Spill2(props: HTMLChakraProps<'div'>) {
  return (
    <chakra.div
      position="absolute"
      left={{ base: '-3vw', md: '0' }}
      top={0}
      display="flex"
      justifyContent="flex-end"
      maxH="80vw"
      maxW={{ base: '87vw', md: '60vw' }}
      pointerEvents="none"
      {...props}
      __css={{
        '> div': {
          height: '100%',
          width: 'auto',
          display: 'flex',
          justifyContent: 'flex-end',
        },
      }}
    >
      <Image
        src={`/images/homepage/splash-section-2${useDarkSuffix()}.svg`}
        alt=""
        objectFit="fill"
        width="686"
        height="710"
      />
    </chakra.div>
  )
}
